import axiosApi from '../../_mockApis/axiosApi';
import config from '../../config';
import axios from 'axios';

const getCompanyList = () => {
  return axiosApi({
    method: 'get',
    url: `${config.baseUrl}/companies/companies?pageSize=1000`
  });
};

const createCompany = async data => {
  return await axiosApi({
    method: 'post',
    url: `${config.baseUrl}/companies/company`,
    data
  });
};

const createInvoice = async data => {
  return await axiosApi({
    method: 'post',
    url: `${config.baseUrl}/companies/invoice`,
    data
  });
};

const getInvoiceList = () => {
  return axiosApi({
    method: 'get',
    url: `${config.baseUrl}/companies/invoices?pageSize=1000`
  });
};
const getInvoiceByInvoiceNumber = (company_id, invoice_number) => {
  return axiosApi({
    method: 'get',
    url: `${config.baseUrl}/companies/invoiceByInvoiceNumber?invoice_number=${invoice_number}&company_id=${company_id}`
  });
};
const getReconciledByInvoiceNumber = (company_id, invoice_number) => {
  return axiosApi({
    method: 'get',
    url: `${config.baseUrl}/companies/reconciledByInvoiceNumber?invoice_number=${invoice_number}&company_id=${company_id}`
  });
};
const deleteInvoice = async data => {
  return await axiosApi({
    method: 'DELETE',
    url: `${config.baseUrl}/companies/invoice`,
    data: data
  });
};
const updateCompany = async data => {
  return await axiosApi({
    url: `${config.baseUrl}/companies/company`,
    method: 'PUT',
    data
  });
};

const deleteCompany = async id => {
  return await axiosApi({
    method: 'DELETE',
    url: `${config.baseUrl}/companies/company`,
    data: { id }
  });
};

const getCompanyByName = company_name => {
  return axios({
    method: 'get',
    url: `${config.baseUrl}/companies/company?company_name=${company_name}`,
    headers: {
      Authorization: localStorage.getItem('token'),
      'x-api-key': config.xApiKey
    }
  });
};

const getCompanyById = id => {
  return axiosApi({
    method: 'get',
    url: `${config.baseUrl}/companies/companyById?company_id=${id}`
  });
};

const getWarehousesList = () => {
  const params = {
    page: 0,
    rowsPerPage: 500
  };
  return axiosApi({
    method: 'post',
    url: `${config.baseUrl}/companies/warehouses`,
    data: params
  });
};

const getWarehouseById = id => {
  return axiosApi({
    method: 'get',
    url: `${config.baseUrl}/companies/warehouse?id=${id}`
  });
};

const createWarehouse = async data => {
  return axiosApi({
    method: 'post',
    url: `${config.baseUrl}/companies/warehouse`,
    data
  });
};

const createWareHouseLevelData = async data => {
  return await axiosApi({
    method: 'post',
    url: `${config.baseUrl}/companies/warehouse-base-price`,
    data
  });
};

const updateWarehouse = async data => {
  return await axiosApi({
    url: `${config.baseUrl}/companies/warehouse`,
    method: 'PUT',
    data
  });
};

const updateWarehouseLevel = async data => {
  return await axiosApi({
    method: 'put',
    url: `${config.baseUrl}/companies/warehouse-base-price`,
    data
  });
};

const deleteWarehouse = async id => {
  return await axiosApi({
    method: 'DELETE',
    url: `${config.baseUrl}/companies/warehouse`,
    data: { id }
  });
};

const getWarehouseLevelData = async id => {
  return await axiosApi({
    method: 'get',
    url: `${config.baseUrl}/companies/warehouses-by-id?warehouse_id=${id}`
  });
};

const createZoneLevelData = async data => {
  return await axiosApi({
    method: 'post',
    url: `${config.baseUrl}/companies/zone-level`,
    data
  });
};

const getZoneLevelDataByWareHouseId = async (warehouse_id, page = 1, page_size = 5000) => {
  return await axiosApi({
    method: 'get',
    url: `${config.baseUrl}/companies/zone-level-by-warehouseId?warehouse_id=${warehouse_id}&page=${page}&rowsPerPage=${page_size}`
  });
};

const deleteWareHouseLevel = async (id, ware_id) => {
  return await axiosApi({
    method: 'DELETE',
    url: `${config.baseUrl}/companies/warehouse-base-price`,
    data: { id, warehouse_id: ware_id }
  });
};

const updateZoneLevelData = async data => {
  return await axiosApi({
    method: 'put',
    url: `${config.baseUrl}/companies/zone-level`,
    data
  });
};

const deleteZoneLevelData = async data => {
  return await axiosApi({
    method: 'delete',
    url: `${config.baseUrl}/companies/zone-level`,
    data
  });
};

const getWareHouseByCompanyId = id => {
  return axiosApi({
    method: 'get',
    url: `${config.baseUrl}/companies/getWarehousesByCompanyId?company_id=${id}`
  });
};

// this API checks the company name is exist or velid
const getCheckIsUserHasValidCompany = companyName => {
  return axiosApi({
    method: 'get',
    url: `${config.baseUrl}/companies/companyExists?companyName=${companyName}`
  });
};

const getCompanyLogoUrl = async company_logo => {
  return axiosApi({
    method: 'get',
    url: `${config.baseUrl}/companies/get-company-logo?company_logo=${company_logo}`
  });
};

export {
  getCompanyList,
  createCompany,
  updateCompany,
  deleteCompany,
  getCompanyByName,
  getCompanyById,
  getWarehousesList,
  getWarehouseById,
  createWarehouse,
  createWareHouseLevelData,
  updateWarehouse,
  updateWarehouseLevel,
  deleteWarehouse,
  getWarehouseLevelData,
  createZoneLevelData,
  getZoneLevelDataByWareHouseId,
  deleteWareHouseLevel,
  updateZoneLevelData,
  deleteZoneLevelData,
  getWareHouseByCompanyId,
  getCheckIsUserHasValidCompany,
  getCompanyLogoUrl,
  createInvoice,
  getInvoiceList,
  deleteInvoice,
  getInvoiceByInvoiceNumber,
  getReconciledByInvoiceNumber
};
